import { createContext, useState } from "react";
import type { FC } from "react";
import PL from "../../lib";

const initialState: PT.StateSettings = {
  updated: Date.now(),
  notifications: [],
  alerts: [],
  badges: [],
  posts: {
    image_fit: false,
    highlight: true,
  },
  post_preference: "TIMED",
};

export interface SettingsContextValue {
  state: PT.StateSettings;
  setSettings: (data: Partial<PT.StateSettings>) => void;
  resetSettings: () => void;
}

const SettingsContext = createContext<SettingsContextValue>({
  state: PL.LocalStorage.get.stateSettings(initialState),
  setSettings: () => {},
  resetSettings: () => {},
});

export const SettingsProvider: FC = (props) => {
  const [state, setState] = useState<PT.StateSettings>(
    PL.LocalStorage.get.stateSettings(initialState)
  );

  function setSettings(data: Partial<PT.StateSettings>) {
    setState(
      PL.LocalStorage.set.stateSettings({
        ...state,
        ...data,
        updated: Date.now(),
      })
    );
  }

  function resetSettings() {
    setState(PL.LocalStorage.set.stateSettings(initialState));
  }

  return (
    <SettingsContext.Provider
      value={{
        state,
        setSettings,
        resetSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
