type Config = {
  CONFIG_ENV: PT.ENV;
  NPM_CMD: PT.NPM_CMD;
  CLIENT_BASE_URL: string;
  API_BASE_URL: string;
  ENCRYPTION_WEB_KEY: string;
  STRIPE_WEB_KEY: string;
  getUrlCDN: (p: PT.CDNPrefix) => string;
};

const LOCAL_CONFIG = {
  API_HOST: "http://localhost",
  API_PORT: 3030,
  CLIENT_HOST: "localhost",
  CLIENT_PORT: 3000,
};

function getConfig() {
  let CONFIG: Config = {
    CONFIG_ENV: process.env.REACT_APP_CONFIG_ENV! as PT.ENV,
    NPM_CMD: process.env.REACT_APP_NPM_CMD! as PT.NPM_CMD,
    CLIENT_BASE_URL: "",
    API_BASE_URL: "",
    ENCRYPTION_WEB_KEY: "",
    STRIPE_WEB_KEY: "",
    getUrlCDN: (cdn: PT.CDNPrefix) => {
      switch (cdn) {
        case "assets":
          return `https://34.cdn.pearesh.com`;
        case "posts":
          return `https://41.cdn.pearesh.com`;
        case "users":
          return `https://46.cdn.pearesh.com`;
        case "newsletters":
          return `https://57.cdn.pearesh.com`;
      }
    },
  };

  // LOCAL DEV FRONTEND USING LOCAL BACKEND
  if (CONFIG.NPM_CMD === "start" && CONFIG.CONFIG_ENV === "local") {
    CONFIG = {
      ...CONFIG,
      CLIENT_BASE_URL: `${LOCAL_CONFIG.CLIENT_HOST}:${LOCAL_CONFIG.CLIENT_PORT}`,
      getUrlCDN: (cdn: PT.CDNPrefix) => {
        if (cdn === "newsletters") {
          return `https://d2659sohievu7s.cloudfront.net`;
        } else {
          return `https://d16sjq4qre4ob3.cloudfront.net/${cdn}`;
        }
      },
      API_BASE_URL: `${LOCAL_CONFIG.API_HOST}:${LOCAL_CONFIG.API_PORT}`,
      ENCRYPTION_WEB_KEY: "b2df428b9929d3ace7c598bbf4e496b2",
      STRIPE_WEB_KEY: "p_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG",
    };
  }

  // LOCAL DEV FRONTEND USING CLOUD DEV BACKEND
  if (CONFIG.NPM_CMD === "start" && CONFIG.CONFIG_ENV === "dev") {
    CONFIG = {
      ...CONFIG,
      CLIENT_BASE_URL: `${LOCAL_CONFIG.CLIENT_HOST}:${LOCAL_CONFIG.CLIENT_PORT}`,
      getUrlCDN: (cdn: PT.CDNPrefix) => {
        if (cdn === "newsletters") {
          return `https://d2659sohievu7s.cloudfront.net`;
        } else {
          return `https://d16sjq4qre4ob3.cloudfront.net/${cdn}`;
        }
      },
      API_BASE_URL: `http://peareshapi-dev-env.v4pqym33i7.us-west-2.elasticbeanstalk.com`,
      ENCRYPTION_WEB_KEY: "b2df428b9929d3ace7c598bbf4e496b2",
      STRIPE_WEB_KEY: "p_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG",
    };
  }

  // LOCAL DEV FRONTEND USING CLOUD PROD BACKEND
  if (CONFIG.NPM_CMD === "start" && CONFIG.CONFIG_ENV === "prod") {
    CONFIG = {
      ...CONFIG,
      CLIENT_BASE_URL: `${LOCAL_CONFIG.CLIENT_HOST}:${LOCAL_CONFIG.CLIENT_PORT}`,
      getUrlCDN: (cdn: PT.CDNPrefix) => {
        switch (cdn) {
          case "assets":
            return `https://34.cdn.pearesh.com`;
          case "posts":
            return `https://41.cdn.pearesh.com`;
          case "users":
            return `https://46.cdn.pearesh.com`;
          case "newsletters":
            return `https://57.cdn.pearesh.com`;
        }
      },
      // API_BASE_URL: `https://api.pearesh.com`, -ElasticBeanstalk,
      API_BASE_URL: `https://705i0ma774.execute-api.us-west-2.amazonaws.com/prod`,
      ENCRYPTION_WEB_KEY: "b2df428b9929d3ace7c598bbf4e496b2",
      STRIPE_WEB_KEY: "p_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG",
    };
  }

  // CLOUD DEV FRONTEND USING CLOUD DEV BACKEND
  if (CONFIG.NPM_CMD === "build" && CONFIG.CONFIG_ENV === "dev") {
    CONFIG = {
      ...CONFIG,
      CLIENT_BASE_URL: "https://d1yuzlafe71i7c.cloudfront.net",
      getUrlCDN: (cdn: PT.CDNPrefix) => {
        if (cdn === "newsletters") {
          return `https://d2659sohievu7s.cloudfront.net`;
        } else {
          return `https://d16sjq4qre4ob3.cloudfront.net/${cdn}`;
        }
      },
      API_BASE_URL: `http://peareshapi-dev-env.v4pqym33i7.us-west-2.elasticbeanstalk.com`,
      ENCRYPTION_WEB_KEY: "b2df428b9929d3ace7c598bbf4e496b2",
      STRIPE_WEB_KEY: "p_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG",
    };
  }

  // CLOUD PROD FRONTEND USING CLOUD PROD BACKEND
  if (CONFIG.NPM_CMD === "build" && CONFIG.CONFIG_ENV === "prod") {
    CONFIG = {
      ...CONFIG,
      CLIENT_BASE_URL: `pearesh.com`,
      getUrlCDN: (cdn: PT.CDNPrefix) => {
        switch (cdn) {
          case "assets":
            return `https://34.cdn.pearesh.com`;
          case "posts":
            return `https://41.cdn.pearesh.com`;
          case "users":
            return `https://46.cdn.pearesh.com`;
          case "newsletters":
            return `https://57.cdn.pearesh.com`;
        }
      },
      // API_BASE_URL: `https://api.pearesh.com`, -ElasticBeanstalk,
      API_BASE_URL: `https://705i0ma774.execute-api.us-west-2.amazonaws.com/prod`,
      ENCRYPTION_WEB_KEY: "b2df428b9929d3ace7c598bbf4e496b2",
      STRIPE_WEB_KEY: "p_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG",
    };
  }

  for (let key in CONFIG) {
    // @ts-expect-error : check that String  values arent null or empty strings
    if (CONFIG[key] == null || CONFIG[key] === "") {
      throw new Error(`${key} env variable is not provided!`);
    }
  }

  return CONFIG;
}

export default getConfig();
