import React from "react";
import { useRoutes } from "react-router-dom";

import { Spin } from "antd";
import { EntypoCircularGraph } from "react-entypo-icons";

import CheckpointModal from "../../components/CheckpointModal";
import CommonComponents from "../../components/common";
import PL from "../../lib";
import Config from "../../config";
import API from "../../api";
import routes from "../../routes";
import useAuth from "../../hooks/useAuth";

import "./style.scss";
import useLoading from "../../hooks/useLoading";
import useAccount from "../../hooks/useAccount";
import useSettings from "../../hooks/useSettings";

function App() {
  const route = useRoutes(routes);

  const { state: account, resetAccount, setAccount } = useAccount();
  const { state: auth, resetAuth, setAuth } = useAuth();
  const { state: settings } = useSettings();
  const { state: loading } = useLoading();

  React.useEffect(() => {
    if (auth.auth != null) {
      checkAuth({ auth: auth.auth });
    } else if (account.badge == null) {
      // TODO : clear posts?
      // resetPosts();
      resetAccount();
      resetAuth();
    }

    // eslint-disable-next-line
  }, []);

  async function checkAuth({ auth }: { auth: string }) {
    const response = await API.Column.auth({
      headers: {
        "Pearesh-Auth": auth,
      },
      query: {
        ecs: PL.Base64.encode(PL.Encrypt.encryptIv(JSON.stringify(settings))),
      },
    });

    if (response.type === "OK") {
      if (account.type === "AUTHOR") {
        setAccount({
          old_subscribers: account.subscribers,
          ...response.result.account,
        });
      } else {
        setAccount({
          type: "AUTHOR",
          old_subscribers: response.result.account.subscribers,
          ...response.result.account,
        });
      }

      setAuth({ authorized_on: Date.now() });
    } else if (response.message) {
      CommonComponents.toastMessage({ type: "ERROR", text: response.message });
      // TODO : clear posts?
      // resetPosts();
      resetAccount();
      resetAuth();
    }
  }

  return (
    <main className={"App"}>
      {Config.CONFIG_ENV === "dev" && auth.checkpoint == null ? (
        <CheckpointModal />
      ) : (
        <Spin
          size="large"
          wrapperClassName={"app-spin-loader spin-loader"}
          spinning={loading.loading}
          tip={"One moment..."}
          indicator={<EntypoCircularGraph className="anticon-spin" />}
        >
          {route}
        </Spin>
      )}
    </main>
  );
}

export default App;
