import { v4 as uuidv4 } from "uuid";
import Config from "../config";
import FetchAPI from "./fetch";
import { initialState as initialAccount } from "../hooks/contexts/AccountContext";
import PL from "../lib";

function getStandardHeaders() {
  const user = PL.LocalStorage.get.stateAccount(initialAccount);

  return {
    "Pearesh-Token": uuidv4(),
    "Pearesh-Badge": user.badge,
  };
}

async function factory({
  domain,
  subdomain,
  method,
  data = null,
  query = null,
  headers = {},
  id = null,
  encrypt = true,
}: PT.FactoryArgs) {
  let url = `${Config.API_BASE_URL}/v1/${domain}`;

  if (id) {
    url += `/${id}`;
  }

  if (subdomain) {
    url += `/${subdomain}`;
  }

  if (query) {
    url += `?`;
    let queries = [];
    for (let [key, value] of Object.entries(query)) {
      if (value) {
        if (value instanceof Array) {
          queries.push(`${key}=${JSON.stringify(value)}`);
        } else {
          queries.push(`${key}=${value}`);
        }
      }
    }
    url += queries.join("&");
  }

  let transformed_data = null;
  if (data) {
    if (encrypt) {
      if (typeof data !== "string") {
        transformed_data = PL.Encrypt.encryptIv(JSON.stringify(data));
      } else {
        transformed_data = PL.Encrypt.encryptIv(data);
      }
    } else {
      transformed_data = data;
    }
  }

  return FetchAPI[method]
    .call(
      // @ts-ignore : weird 'this' type with dynamic FetchAPI call
      this,
      url,
      {
        ...getStandardHeaders(),
        ...headers,
      },
      // @ts-ignore : based on dynamic FetchAPI call, there will be 4 args
      transformed_data
    )
    .then(async (res: Response) => {
      let res_json = await res.json();
      let decrypted_res = PL.Encrypt.decryptIv(res_json.reply);
      let parsed_res = JSON.parse(decrypted_res);

      PL.Util.debugMessage({
        prefix: `API Factory Response - [${method}]::${domain}${
          id ? "/" + id : ""
        }${subdomain ? "/" + subdomain : ""} :`,
        msg: parsed_res,
      });

      return parsed_res;
    });
}

const apiLib = {
  authorizeVisitor: ({
    headers,
  }: PT.Route.checkpoint.APIRequest): Promise<PT.Route.checkpoint.Response> =>
    factory({ method: "get", domain: "checkpoint", headers }),

  Subscriber: {
    manageSubscriptions: ({
      data,
    }: PT.Route.updateSubscriptions.WebRequest): Promise<PT.Route.updateSubscriptions.Response> =>
      factory({
        method: "post",
        domain: "subscribers",
        subdomain: "update_subscriptions",
        data,
      }),

    updatePeriodicalType: ({
      data,
    }: PT.Route.updatePeriodicalType.WebRequest): Promise<PT.Route.updatePeriodicalType.Response> =>
      factory({
        method: "post",
        domain: "subscribers",
        subdomain: "update_type",
        data,
      }),
  },

  Email: {
    parseQuery: ({
      query,
    }: PT.Route.parseEmailQuery.WebRequest): Promise<PT.Route.parseEmailQuery.Response> =>
      factory({ method: "get", domain: "email", query }),
  },

  Post: {
    get: ({
      id,
      query,
      headers,
    }: PT.Route.getPost.WebRequest): Promise<PT.Route.getPost.Response> =>
      factory({ method: "get", domain: "posts", id, query, headers }),

    getList: ({
      query,
    }: PT.Route.getPostList.WebRequest): Promise<PT.Route.getPostList.Response> =>
      factory({ method: "get", domain: "posts", query }),

    createImage: ({
      id,
      query,
      data,
    }: PT.Route.createPostImage.WebRequest): Promise<PT.Route.createPostImage.Response> =>
      factory({
        method: "post",
        domain: "posts",
        id,
        subdomain: "images",
        query,
        data,
        encrypt: false,
      }),

    getImage: ({
      query,
      id,
      headers,
    }: PT.Route.getPostImages.WebRequest): Promise<PT.Route.getPostImages.Response> =>
      factory({
        method: "get",
        domain: "posts",
        id,
        subdomain: "images",
        query,
        headers,
      }),

    create: ({
      data,
    }: PT.Route.createPost.WebRequest): Promise<PT.Route.createPost.Response> =>
      factory({ method: "post", domain: "posts", data }),

    update: ({
      id,
      data,
    }: PT.Route.updatePost.WebRequest): Promise<PT.Route.updatePost.Response> =>
      factory({ method: "post", domain: "posts", id, data }),

    delete: ({
      id,
      query,
    }: PT.Route.deletePost.WebRequest): Promise<PT.Route.deletePost.Response> =>
      factory({ method: "del", domain: "posts", id, query }),

    publish: ({
      id,
      data,
    }: PT.Route.publishPost.WebRequest): Promise<PT.Route.publishPost.Response> =>
      factory({
        method: "post",
        domain: "posts",
        id,
        subdomain: "publish",
        data,
      }),
  },

  Column: {
    create: ({
      data,
    }: PT.Route.createColumn.WebRequest): Promise<PT.Route.createColumn.Response> =>
      factory({ method: "post", domain: "columns", data }),

    get: ({
      id,
    }: PT.Route.getColumn.WebRequest): Promise<PT.Route.getColumn.Response> =>
      factory({ method: "get", domain: "columns", id }),

    update: ({
      id,
      headers,
      data,
    }: PT.Route.updateColumn.WebRequest): Promise<PT.Route.updateColumn.Response> =>
      factory({ method: "post", domain: "columns", id, data, headers }),

    createImage: ({
      query,
      data,
    }: PT.Route.createColumnImage.WebRequest): Promise<PT.Route.createColumnImage.Response> =>
      factory({
        method: "post",
        domain: "columns",
        subdomain: "images",
        query,
        data,
        encrypt: false,
      }),

    changePassword: ({
      id,
      headers,
      data,
    }: PT.Route.changePasswordColumn.WebRequest): Promise<PT.Route.changePasswordColumn.Response> =>
      factory({
        method: "post",
        domain: "columns",
        subdomain: "password",
        id,
        data,
        headers,
      }),

    login: ({
      data,
    }: PT.Route.loginColumn.WebRequest): Promise<PT.Route.loginColumn.Response> =>
      factory({ method: "post", domain: "columns", subdomain: "login", data }),

    auth: ({
      headers,
      query,
    }: PT.Route.authColumn.WebRequest): Promise<PT.Route.authColumn.Response> =>
      factory({
        method: "post",
        domain: "columns",
        subdomain: "auth",
        headers,
        query,
      }),

    sendVerifyEmail: ({
      data,
    }: PT.Route.sendVerifyEmail.WebRequest): Promise<PT.Route.sendVerifyEmail.Response> =>
      factory({ method: "post", domain: "columns", subdomain: "verify", data }),

    sendForgotPasswordEmail: ({
      data,
    }: PT.Route.forgotPasswordColumn.WebRequest): Promise<PT.Route.forgotPasswordColumn.Response> =>
      factory({ method: "post", domain: "columns", subdomain: "forgot", data }),

    subscribe: ({
      id,
      data,
    }: PT.Route.subscribeColumn.WebRequest): Promise<PT.Route.subscribeColumn.Response> =>
      factory({
        method: "post",
        domain: "columns",
        id,
        subdomain: "subscribe",
        data,
      }),

    getDailyPosts: ({
      id,
      query,
    }: PT.Route.getDailyPosts.WebRequest): Promise<PT.Route.getDailyPosts.Response> =>
      factory({
        method: "get",
        domain: "columns",
        id,
        subdomain: "posts",
        query,
      }),
  },

  getStandardHeaders,
};

export default apiLib;
