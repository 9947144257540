import React from "react";
import { Helmet } from "react-helmet-async";
import { notification, Button, message, Alert, Badge } from "antd";
import {
  EntypoCheck,
  EntypoCircleWithCross,
  EntypoInfoWithCircle,
} from "react-entypo-icons";
import ClipboardJS from "clipboard";

import Config from "../config";
import useSettings from "../hooks/useSettings";
// import PL from "../lib";

type MessageType = "ERROR" | "INFO" | "SUCCESS";

function getMessageIcon(type: MessageType) {
  switch (type) {
    case "INFO":
      return <EntypoInfoWithCircle className="message-info" />;
    case "ERROR":
      return <EntypoCircleWithCross className="message-cross" />;
    case "SUCCESS":
      return <EntypoCheck className="message-check" />;
  }
}

function toastMessage({
  duration = 3,
  type = "INFO",
  text,
  err,
}: {
  type: MessageType;
  text?: string | null;
  duration?: number;
  err?: Error;
}): void {
  if (err != null && Config.CONFIG_ENV !== "prod") {
    console.error(err);
  }

  if (text || err) {
    message.open({
      duration: duration,
      content: text || err?.message,
      icon: getMessageIcon(type),
      type: "info",
    });
  } else {
    throw new Error(
      "Error occurered and there was no message or Error attached."
    );
  }
}

function copySuccess(e: ClipboardJS.Event) {
  toastMessage({ type: "SUCCESS", text: `Copied: "${e.text}".`, duration: 3 });
  e.clearSelection();
}

function copyFailed(e: ClipboardJS.Event) {
  toastMessage({
    type: "ERROR",
    text: "There was an issue copying the text.",
    duration: 3,
  });
}

function NotificationMessage(props: {
  config: {
    key: PT.NotificationMessageKey;
    message: string;
    description: string | React.ReactNode;
    className?: string;
    duration?: number;
    dismissable?: boolean;
    icon: JSX.Element;
  };
  children?: React.ReactNode;
}) {
  const { state: settings, setSettings } = useSettings();

  const args = {
    key: props.config.key,
    className: `app-notification ${props.config.className}`,
    message: props.config.message,
    description: props.config.description,
    duration: props.config.duration || 0,
    btn: (
      <Button
        onClick={() => {
          if (props.config.dismissable) {
            setSettings({
              notifications: [
                ...new Set([...settings.notifications, props.config.key]),
              ],
            });
          }
          notification.close(props.config.key);
        }}
      >
        {"Dismiss"}
      </Button>
    ),
    icon: props.config.icon || null,
  };

  if (props.children != null) {
    if (!settings.notifications.includes(props.config.key)) {
      notification.open(args);
    }
    return (
      <span onClick={() => notification.open(args)}>{props.children}</span>
    );
  } else {
    if (!settings.notifications.includes(props.config.key)) {
      notification.open(args);
    }
    return <span></span>;
  }
}

function AlertMessage(props: {
  message: string | React.ReactNode;
  id: PT.AlertMessageKey;
  className?: string;
  closable?: boolean;
}) {
  const { state: settings, setSettings } = useSettings();

  return (
    <section
      key={`alert-message-${props.id}`}
      className={`alert-message-help ${props.id.toLowerCase()}-help ${
        props.className || ""
      }`}
    >
      {!settings.alerts.includes(props.id) && (
        <Alert
          className={`AlertMessage ${props.id.toLowerCase()}-help-alert`}
          closable={props.closable || true}
          onClose={() =>
            setSettings({
              alerts: [...new Set([...settings.alerts, props.id])],
            })
          }
          message={props.message}
          type="warning"
        />
      )}
    </section>
  );
}

function DotWrapper(props: {
  id: PT.DotWrapperKey;
  status?: "success" | "processing" | "default" | "error" | "warning";
  children: React.ReactNode;
  className?: string;
}) {
  const { state: settings, setSettings } = useSettings();

  if (!settings.badges.includes(props.id)) {
    return (
      <span
        onClick={() =>
          setSettings({
            badges: [...new Set([...settings.badges, props.id])],
          })
        }
        className={`DotWrapper ${props.className || ""}`}
      >
        <Badge
          status={props.status || "warning"}
          dot={!settings.badges.includes(props.id)}
        >
          {props.children}
        </Badge>
      </span>
    );
  } else {
    return <span>{props.children}</span>;
  }
}

function getHelmet({ title }: { title?: string }) {
  return <Helmet>{title != null ? <title>{title}</title> : null}</Helmet>;
}

const commonLib = {
  toastMessage,
  NotificationMessage,
  AlertMessage,
  DotWrapper,
  copyFailed,
  copySuccess,
  getHelmet,
};

export default commonLib;
