import Config from "../config";

const MILLIS = 1000;
const SECOND = MILLIS * 60;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

const IMAGE_MAX_MB_SIZE = 16;
const POST_MAX_IMAGE_COUNT = 30;

function getMillis(interval: PT.Post.Interval, quantity: number) {
  switch (interval) {
    case "SECONDS":
      return quantity * MILLIS;
    case "MINUTES":
      return quantity * 60 * MILLIS;
    case "HOURS":
      return quantity * 60 * 60 * MILLIS;
    case "DAYS":
      return quantity * 24 * 60 * 60 * MILLIS;
    default:
      throw new Error("Did not have a proper Interval");
  }
}

function getRandomInt(max: number): number {
  return Math.floor(Math.random() * Math.floor(max));
}

function getDataSrc({ format, data }: { format: string; data: string }) {
  return `data:image/${format};base64,${data}`;
}

const HASHED_STRINGS = {
  thumbnail: hashString("thumbnail"),
  original: hashString("original"),
  preview: hashString("preview"),
  teaser: hashString("teaser"),
};

function getNewsletterPreviewIdDir({
  column_name,
  post_id,
}: {
  column_name: string;
  post_id: string;
}) {
  return {
    dir: `${HASHED_STRINGS.preview}/${column_name.toLowerCase()}/${post_id}`,
    id: `${hashString(post_id)}.png`,
  };
}

function getContentImageSrc({
  image,
  dir,
  thumbnail = false,
}: {
  image: PT.Image;
  dir: string;
  thumbnail?: boolean;
}): string {
  if (image.raw) {
    return getDataSrc({ format: image.raw.format, data: image.raw.data });
  } else if (thumbnail) {
    return getImageSrc({
      cdn: "posts",
      dir: `${HASHED_STRINGS.thumbnail}/${dir}`,
      id: image.id,
    });
  } else {
    return getImageSrc({
      cdn: "posts",
      dir: `${HASHED_STRINGS.original}/${dir}`,
      id: image.id,
    });
  }
}

function getImageSrc({
  cdn,
  dir,
  id,
}: {
  cdn: PT.CDNPrefix;
  id: string;
  dir: string | null;
}): string {
  let directory = dir != null ? `${dir}/` : "";
  return `${Config.getUrlCDN(cdn)}/${directory}${id}`;
}

function capitalizeFirstLetter(string: string): string {
  return string[0] ? `${string[0].toUpperCase()}${string.substring(1)}` : "";
}

function debugMessage({
  prefix = "DEBUG Message: ",
  msg,
  pretty = false,
}: {
  prefix: string | null;
  msg: string | object;
  pretty?: boolean;
}): void {
  if (Config.CONFIG_ENV !== "prod") {
    if (pretty) {
      console.info(prefix, JSON.stringify(msg, null, 2));
    } else {
      console.info(prefix, msg);
    }
  }
}

function isEmailValid(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email);
}

function hashString(str: string) {
  let hash = 5381;
  let i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
}

const POST_LIFECYCLE = {
  EXPIRATION_DAYS: 30,
};

const utilLib = {
  POST_LIFECYCLE,
  MILLIS,
  SECOND,
  MINUTE,
  HOUR,
  POST_MAX_IMAGE_COUNT,
  IMAGE_MAX_MB_SIZE,
  getMillis,
  getDataSrc,
  getImageSrc,
  getContentImageSrc,
  capitalizeFirstLetter,
  debugMessage,
  isEmailValid,
  getRandomInt,
  hashString,
  HASHED_STRINGS,
  getNewsletterPreviewIdDir,
};

export default utilLib;
