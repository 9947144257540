import moment from "moment";
import Util from "./util";

const DAY_MAP_BY_NUMBER: { [num: number]: string } = {
  0: "sun",
  1: "mon",
  2: "tue",
  3: "wed",
  4: "thu",
  5: "fri",
  6: "sat",
};

const DAY_MAP_BY_STRING: { [str: string]: string } = {
  sun: "Sunday",
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
};

function timeFromNow({ unix }: { unix: number }) {
  return moment(unix).fromNow();
}

function getFormatString({
  type,
}: {
  type:
    | "PRETTY_NO_YEAR"
    | "FULL"
    | "ISO"
    | "MONTH_DAY"
    | "PRETTY"
    | "PRETTY_NO_WEEKDAY";
}) {
  switch (type) {
    case "ISO":
      return "yyyy-MM-DD";
    case "FULL":
      return undefined; // "2014-09-08T08:02:17-05:00" (ISO 8601, no fractional seconds)
    case "MONTH_DAY":
      return "MMM. Do";
    case "PRETTY_NO_YEAR":
      return "dddd, MMM. Do";
    case "PRETTY":
      return "dddd, MMM. Do yyyy";
    case "PRETTY_NO_WEEKDAY":
      return "MMM. Do yyyy";
  }
}

function getFormattedDate({
  unix = Date.now(),
  utc = false,
  type = "ISO",
}: {
  unix?: number;
  utc?: boolean;
  type?:
    | "PRETTY_NO_YEAR"
    | "FULL"
    | "ISO"
    | "PRETTY"
    | "MONTH_DAY"
    | "PRETTY_NO_WEEKDAY";
}) {
  if (utc) {
    return moment.utc(unix).format(getFormatString({ type }));
  } else {
    return moment(unix).format(getFormatString({ type }));
  }
}

function parseFromDailyDate({
  date,
  utc = false,
}: {
  date: string;
  utc?: boolean;
}) {
  if (utc) {
    return moment.utc(date);
  } else {
    return moment(date);
  }
}

function getUTCDate({ date }: { date: Date }) {
  // MDN Web Docs - The getUTCMonth() returns the month of the specified date according to universal time, as a zero-based value (where zero indicates the first month of the year).
  let utc_year = date.getUTCFullYear();
  let utc_month = date.getUTCMonth() + 1;
  let utc_day = date.getUTCDate();

  let utc_date = `${utc_year}-${
    utc_month.toString().length === 1 ? `0${utc_month}` : utc_month
  }-${utc_day.toString().length === 1 ? `0${utc_day}` : utc_day}`;
  return utc_date;
}

function getMomentFromUTC({ date }: { date: Date }) {
  let utc_date = getUTCDate({ date });
  let parsed_iso = moment.utc(utc_date);
  return parsed_iso;
}

function getWeeklyStartEndDateUTC({ date }: { date: Date }) {
  let weekly_previous_sunday = new Date(
    date.getTime() - Util.getMillis("DAYS", date.getUTCDay())
  );
  let weekly_next_saturday = new Date(
    date.getTime() + Util.getMillis("DAYS", 6 - date.getUTCDay())
  );

  let formatted_previous_sunday = getMomentFromUTC({
    date: weekly_previous_sunday,
  });
  let formatted_next_saturday = getMomentFromUTC({
    date: weekly_next_saturday,
  });

  return [
    new Date(formatted_previous_sunday.unix() * 1000),
    new Date(formatted_next_saturday.unix() * 1000),
  ];
}

function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const datetimeLib = {
  DAY_MAP_BY_NUMBER,
  DAY_MAP_BY_STRING,
  timeFromNow,
  getFormattedDate,
  parseFromDailyDate,
  getWeeklyStartEndDateUTC,
  getUserTimezone,
};

export default datetimeLib;
