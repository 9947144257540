import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {};

export const slice = createSlice({
  name: "mock",
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<object>) => {
      return { ...state, ...action.payload };
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const { set, reset } = slice.actions;

export default slice.reducer;
