import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { TypedUseSelectorHook } from "react-redux";
import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

// import postsReducer from "./posts";
// import accountReducer from "./account";
// import authReducer from "./auth";
// import subscriptionsReducer from "./subscriptions";
// import newsletterReducer from "./newsletter";
// import settingsReducer from "./settings";
import mockReducer from "./mock";

import PL from "../lib";

const rootReducer = combineReducers({
  // posts: postsReducer,
  // account: accountReducer,
  // auth: authReducer,
  // subscriptions: subscriptionsReducer,
  // newsletter: newsletterReducer,
  // settings: settingsReducer,
  mock: mockReducer,
});

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      PL.Logger.error({ prefix: "rtkQueryErrorLogger.rejected", msg: action });
    }

    return next(action);
  };

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(rtkQueryErrorLogger),
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true",
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
