import { createContext, useState } from "react";
import type { FC } from "react";
import PL from "../../lib";
import * as nanoid from "nanoid";

export const initialState: PT.StateAccount = {
  created: Date.now(),
  badge: nanoid.nanoid(6) + "::" + nanoid.nanoid(15),
  type: "GUEST",
};

export interface AccountContextValue {
  state: PT.StateAccount;
  setAccount: (data: Partial<PT.StateAccount>) => void;
  resetAccount: () => void;
}

const AccountContext = createContext<AccountContextValue>({
  state: PL.LocalStorage.get.stateAccount(initialState),
  setAccount: () => {},
  resetAccount: () => {},
});

export const AccountProvider: FC = (props) => {
  const [state, setState] = useState<PT.StateAccount>(
    PL.LocalStorage.get.stateAccount(initialState)
  );

  function setAccount(data: Partial<PT.StateAccount>) {
    setState(
      PL.LocalStorage.set.stateAccount({ ...state, ...data } as PT.StateAccount)
    );
  }

  function resetAccount() {
    setState(PL.LocalStorage.set.stateAccount(initialState));
  }

  return (
    <AccountContext.Provider
      value={{
        state,
        setAccount,
        resetAccount,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export const AccountConsumer = AccountContext.Consumer;

export default AccountContext;
