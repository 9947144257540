import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import App from "./containers/App";
import { store } from "./store";
import Config from "./config";
import { AuthProvider } from "./hooks/contexts/AuthContext";
import { SettingsProvider } from "./hooks/contexts/SettingsContext";
import { AccountProvider } from "./hooks/contexts/AccountContext";
import { LoadingProvider } from "./hooks/contexts/LoadingContext";
import { BackendProvider } from "./hooks/contexts/BackendContext";
import * as serviceWorker from "./serviceWorker";

import "./index.scss";

const disableReactDevTools = () => {
  const noop = () => undefined;
  // @ts-ignore: Custom env for React Devtools Extension
  const DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === "object") {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === "function" ? noop : null;
    }
  }
};

if (process.env.NODE_ENV !== "development" || Config.CONFIG_ENV === "prod") {
  disableReactDevTools();
}

ReactDOM.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <AccountProvider>
            <LoadingProvider>
              <SettingsProvider>
                <BackendProvider>
                  <App />
                </BackendProvider>
              </SettingsProvider>
            </LoadingProvider>
          </AccountProvider>
        </AuthProvider>
      </BrowserRouter>
    </ReduxProvider>
  </HelmetProvider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
