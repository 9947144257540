import React from "react";
import { Modal, Input, Button } from "antd";

// import PL from "../../lib";
import CommonComponents from "../common";
import API from "../../api";
import useAuth from "../../hooks/useAuth";
import "./style.scss";

function CheckpointModal(props: {}) {
  const { setAuth } = useAuth();

  const [security, setSecurity] = React.useState<{
    password: string;
    loading: boolean;
  }>({
    password: "",
    loading: false,
  });

  async function checkPassword() {
    setSecurity({ ...security, loading: true });

    let response = await API.authorizeVisitor({
      headers: { "Pearesh-Checkpoint": security.password },
    });

    if (response.type === "OK") {
      setAuth({ checkpoint: security.password });

      setSecurity({ password: "", loading: false });
    } else {
      CommonComponents.toastMessage({
        type: "ERROR",
        text: "Authentication failed.",
      });
      setSecurity({ ...security, loading: false });
    }
  }

  return (
    <Modal
      confirmLoading={security.loading}
      className="user-auth-modal"
      closable={false}
      title="Authentication Required"
      visible={true}
      okText="Submit"
      onOk={checkPassword}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={checkPassword}
          disabled={security.password === ""}
        >
          Submit
        </Button>,
      ]}
    >
      <Input.Password
        onPressEnter={checkPassword}
        onChange={(e) => setSecurity({ ...security, password: e.target.value })}
        placeholder="Password"
      />
    </Modal>
  );
}

export default CheckpointModal;
