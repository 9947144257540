export async function get(url: string, headers: {}) {
  return await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: headers,
  });
}

export async function post(url: string, headers: {}, data: any) {
  return await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: headers,
    body: data,
  });
}

export async function del(url: string, headers: {}) {
  return await fetch(url, {
    method: "DELETE",
    mode: "cors",
    headers: headers,
  });
}

const fetchLib = {
  get,
  post,
  del,
};

export default fetchLib;
