import CryptoJS from "crypto-js";

export function encode(str: string): string {
  const wordArray = CryptoJS.enc.Utf8.parse(str);
  const base64 = CryptoJS.enc.Base64.stringify(wordArray);
  return base64;
}

export function decode(base64: string): string {
  const parsedWordArray = CryptoJS.enc.Base64.parse(base64);
  const str = parsedWordArray.toString(CryptoJS.enc.Utf8);
  return str;
}

const base64Lib = {
  encode,
  decode,
};

export default base64Lib;
