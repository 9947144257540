import config from "../config";

function debug({
  prefix,
  msg,
  pretty = false,
}: {
  prefix: string;
  msg: string | object;
  pretty?: boolean;
}) {
  let message = typeof msg === "string" && pretty ? JSON.parse(msg) : msg;

  if (config.CONFIG_ENV !== "prod") {
    console.debug(`${prefix}: `, message);
  }
}

function error({
  prefix,
  msg,
  pretty = false,
}: {
  prefix: string;
  msg: any;
  pretty?: boolean;
}) {
  let message = typeof msg === "string" && pretty ? JSON.parse(msg) : msg;

  if (config.CONFIG_ENV !== "prod") {
    console.error(`${prefix}: `, message);
  }
}

const Logger = { debug, error };

export default Logger;
