import { createContext, useState } from "react";
import type { FC } from "react";

const initialStateNewsletter: PT.StateNewsletter = {
  daily: null,
  weekly: null,
};

const initialStatePosts: PT.StatePosts = {
  posts: {
    list: [],
    key: null,
  },
};

const initialStateSubscriptions: PT.StateSubscriptions = {
  subscriptions: null,
};

const initialState = {
  ...initialStateNewsletter,
  ...initialStatePosts,
  ...initialStateSubscriptions,
};

export interface BackendContextValue {
  state: PT.StateNewsletter & PT.StatePosts & PT.StateSubscriptions;
  setBackend: (
    data: Partial<PT.StateNewsletter & PT.StatePosts & PT.StateSubscriptions>
  ) => void;
  resetBackend: (
    category: ("posts" | "newsletter" | "subscriptions")[]
  ) => void;
}

const BackendContext = createContext<BackendContextValue>({
  state: initialState,
  setBackend: () => {},
  resetBackend: () => {},
});

export const BackendProvider: FC = (props) => {
  const [state, setState] = useState<
    PT.StateNewsletter & PT.StatePosts & PT.StateSubscriptions
  >(initialState);

  function setBackend(
    data: Partial<PT.StateNewsletter & PT.StatePosts & PT.StateSubscriptions>
  ) {
    setState({ ...state, ...data });
  }

  function resetBackend(
    category: ("posts" | "newsletter" | "subscriptions")[]
  ) {
    let resetState = {};
    if (category.includes("posts"))
      resetState = { ...resetState, ...initialStatePosts };

    if (category.includes("newsletter"))
      resetState = { ...resetState, ...initialStateNewsletter };

    if (category.includes("subscriptions"))
      resetState = { ...resetState, ...initialStateSubscriptions };

    setState({ ...state, ...resetState });
  }

  return (
    <BackendContext.Provider
      value={{
        state,
        setBackend,
        resetBackend,
      }}
    >
      {props.children}
    </BackendContext.Provider>
  );
};

export const BackendConsumer = BackendContext.Consumer;

export default BackendContext;
