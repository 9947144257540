import * as EncryptLib from "./encryption";
import Base64Lib from "./base64";
import LocalStorageLib from "./localstorage";
import DateTimeLib from "./datetime";
import UtilLib from "./util";
import LoggerLib from "./logger";

const peareshLib = {
  Encrypt: EncryptLib,
  Base64: Base64Lib,
  LocalStorage: LocalStorageLib,
  DateTime: DateTimeLib,
  Util: UtilLib,
  Logger: LoggerLib,
};

export default peareshLib;
