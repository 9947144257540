import Base64 from "./base64";

export function getLocalStorage<T extends { [k: string]: any }>(
  key: PT.LocalStorageKey,
  initialState: T = {} as T
): T {
  let data = localStorage.getItem(key);

  if (data) {
    let old_data = JSON.parse(Base64.decode(data));
    return old_data;
  } else {
    let new_data = initialState;
    localStorage.setItem(key, Base64.encode(JSON.stringify(new_data)));
    return new_data;
  }
}

export function setLocalStorage<T extends object>(
  key: PT.LocalStorageKey,
  data: T
): T {
  localStorage.setItem(key, Base64.encode(JSON.stringify(data)));
  return data;
}

const localStorageLib = {
  get: {
    stateSettings: (init: PT.StateSettings): PT.StateSettings =>
      getLocalStorage("PEAR_SETTINGS", init),
    stateAccount: (init: PT.StateAccount): PT.StateAccount =>
      getLocalStorage("PEAR_ACCOUNT", init),
    stateAuth: (init: PT.StateAuth): PT.StateAuth =>
      getLocalStorage("PEAR_AUTHENTICATION", init),
  },
  set: {
    stateSettings: (data: PT.StateSettings) =>
      setLocalStorage("PEAR_SETTINGS", data),
    stateAccount: (data: PT.StateAccount) =>
      setLocalStorage("PEAR_ACCOUNT", data),
    stateAuth: (data: PT.StateAuth) =>
      setLocalStorage("PEAR_AUTHENTICATION", data),
  },
};

export default localStorageLib;
