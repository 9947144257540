import { FC, LazyExoticComponent, Suspense, lazy } from "react";
import { RouteObject } from "react-router-dom";

import { Spin } from "antd";
import { EntypoCircularGraph } from "react-entypo-icons";

import PL from "./lib";
import { BlankPageProps } from "./containers/BlankPage";
import { DetailsProps } from "./components/Details";

const Loadable = (Component: LazyExoticComponent<FC<any>>) => (props: any) =>
  (
    <Suspense
      fallback={
        <Spin
          spinning
          size="large"
          wrapperClassName={"app-spin-loader spin-loader"}
          indicator={<EntypoCircularGraph className="anticon-spin" />}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );

const UploadPage = Loadable(lazy(() => import("./containers/UploadPage")));
const HomePage = Loadable(lazy(() => import("./containers/HomePage")));
const PostPage = Loadable(lazy(() => import("./containers/PostPage")));
const ColumnPage = Loadable(lazy(() => import("./containers/ColumnPage")));
const EmailPage = Loadable(lazy(() => import("./containers/EmailPage")));
const BlankPage: BlankPageProps = Loadable(
  lazy(() => import("./containers/BlankPage"))
);
const AccountPage = Loadable(lazy(() => import("./containers/AccountPage")));
const ManageSubscriptionsPage = Loadable(
  lazy(() => import("./containers/ManageSubscriptionsPage"))
);
const NewsletterDailyPage = Loadable(
  lazy(() => import("./containers/NewsletterDailyPage"))
);
const NewsletterWeeklyPage = Loadable(
  lazy(() => import("./containers/NewsletterWeeklyPage"))
);
const Terms = Loadable(lazy(() => import("./components/Terms")));
const Privacy = Loadable(lazy(() => import("./components/Privacy")));
const FAQ = Loadable(lazy(() => import("./components/FAQ")));
const Details: DetailsProps = Loadable(
  lazy(() => import("./components/Details"))
);

const routes: RouteObject[] = [
  {
    path: "",
    element: <HomePage />,
  },
  {
    path: "upload",
    element: <UploadPage />,
  },
  {
    path: "q/email",
    element: <EmailPage />,
  },
  {
    path: "m/subscriptions",
    element: <ManageSubscriptionsPage />,
  },
  {
    path: "newsletter",
    children: [
      {
        path: "daily",
        element: <NewsletterDailyPage />,
      },
      {
        path: "weekly",
        element: <NewsletterWeeklyPage />,
      },
    ],
  },
  {
    path: "account",
    element: <AccountPage />,
  },
  {
    path: "help",
    children: [
      {
        path: "terms",
        element: (
          <BlankPage title="Terms" className="TermsPage">
            <Terms />
          </BlankPage>
        ),
      },
      {
        path: "privacy",
        element: (
          <BlankPage title="Privacy" className="PrivacyPage">
            <Privacy />
          </BlankPage>
        ),
      },
      {
        path: "faq",
        element: (
          <BlankPage title="FAQ" className="FAQPage">
            <FAQ />
          </BlankPage>
        ),
      },
    ],
  },
  {
    path: "post/:id",
    element: <PostPage />,
  },
  {
    path: ":id",
    element: <ColumnPage />,
  },
  {
    path: "*",
    element: (
      <BlankPage className="ErrorPage">
        <Details
          tag={"Error: 404"}
          bg={PL.Util.getRandomInt(11) + 1}
          text={
            "Sorry about that, it looks like we can't find the page you visited."
          }
        />
      </BlankPage>
    ),
  },
];

export default routes;
