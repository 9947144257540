import { createContext, useState } from "react";
import type { FC } from "react";
import PL from "../../lib";

const initialState: PT.StateAuth = {
  checkpoint: null,
  auth: null,
  authorized_on: null,
  authenticated_on: null,
};

export interface AuthContextValue {
  state: PT.StateAuth;
  setAuth: (data: Partial<PT.StateAuth>) => void;
  resetAuth: () => void;
}

const AuthContext = createContext<AuthContextValue>({
  state: PL.LocalStorage.get.stateAuth(initialState),
  setAuth: () => {},
  resetAuth: () => {},
});

export const AuthProvider: FC = (props) => {
  const [state, setState] = useState<PT.StateAuth>(
    PL.LocalStorage.get.stateAuth(initialState)
  );

  function setAuth(data: Partial<PT.StateAuth>) {
    setState(PL.LocalStorage.set.stateAuth({ ...state, ...data }));
  }

  function resetAuth() {
    setState(PL.LocalStorage.set.stateAuth(initialState));
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        setAuth,
        resetAuth,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
