import { createContext, useState } from "react";
import type { FC } from "react";

const initialState: PT.LoadingState = {
  loading: false,
};

export interface LoadingContextValue {
  state: PT.LoadingState;
  setLoading: (loading: boolean) => void;
}

const LoadingContext = createContext<LoadingContextValue>({
  state: initialState,
  setLoading: () => {},
});

export const LoadingProvider: FC = (props) => {
  const [state, setState] = useState<PT.LoadingState>(initialState);

  function setLoading(loading: boolean) {
    setState({ loading });
  }

  return (
    <LoadingContext.Provider
      value={{
        state,
        setLoading,
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  );
};

export const LoadingConsumer = LoadingContext.Consumer;

export default LoadingContext;
