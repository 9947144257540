import CryptoJS from "crypto-js";
import Config from "../config";

export function encryptIv(
  text: string,
  key: string = Config.ENCRYPTION_WEB_KEY
): string {
  const ciphertext = CryptoJS.AES.encrypt(text, key).toString();
  return ciphertext;
}

export function decryptIv(
  text: string,
  key: string = Config.ENCRYPTION_WEB_KEY
): string {
  const bytes = CryptoJS.AES.decrypt(text, key);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}
